import React from "react";
import LOGO from "./../../../Images/AccessibilityTravel/logo.svg";
import SAGELOGO from "./../../../Images/AccessibilityTravel/sage-logo.svg";
import SUPPORT1 from "./../../../Images/AccessibilityTravel/support-img1.png";
import SUPPORT2 from "./../../../Images/AccessibilityTravel/support-img2.png";
import SUPPORT3 from "./../../../Images/AccessibilityTravel/support-img3.png";

export default function MiddleSection() {
  return (
    <div className="at-middle-container">
      <div className="at-middle-container-fst">
        <span>
          TravelGenius and Sage Traveling join forces to develop a solution that
          will transform travel for people with disabilities
        </span>
      </div>
      <div className="at-middle-container-sec">
        <img
          src={LOGO}
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={() => window.open("https://travel-genius.com/", "_blank")}
        />
        <img
          src={SAGELOGO}
          alt="sage logo"
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open("https://www.sagetraveling.com/", "_blank")
          }
        />
      </div>
      <div className="at-middle-container-third">
        <div className="at-card-box">
          <img src={SUPPORT1} alt="support" />
          <span>
            A tech platform that empowers travelers with special needs to embark
            on their journeys with confidence and ease.
          </span>
        </div>
        <div className="at-card-box">
          <img src={SUPPORT2} alt="support" />
          <span>
            By collecting, processing and providing all the necessary
            information for travel planning, it will change the way people with
            special needs approach travel, turning dreams of adventure <br />
            into reality with ease and confidence.
          </span>
        </div>
        <div className="at-card-box pt-80">
          <img src={SUPPORT3} alt="support" />
          <span>
            This global resource ensures that every traveler, regardless of
            ability, can research, book and travel with peace of mind
          </span>
        </div>
      </div>
    </div>
  );
}
