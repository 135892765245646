import React from "react";
import Logo from "../Components/LOGO/Logo";
import MediaIconButton from "../Components/MediaIcon/MediaIconButton";
import { Link, useNavigate } from "react-router-dom";
import FooterLogo from "../Components/LOGO/FooterLogo";

export default function Footer(props) {
  const navigate = useNavigate();
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  const FAQS = () => {
    navigate("/");
    // setTimeout(() => {
    //   if (document.getElementById("faq")) {
    //     var elmntToView = document.getElementById("FAQsS");
    //     elmntToView.scrollIntoView({ block: "start" });
    //   }
    // }, 200);
  };
  return (
    <footer className="paddingLR3P" style={{ backgroundColor: "#081427" }}>
      <div className=" py-4">
        <div className="flexmedia flex-wrap justify-content-between align-items-center py-5">
          <div className="col-md-10 flexmedia justify-content-start">
            <a className="navbar-brand justiStartCenter " href="/">
              <FooterLogo />
            </a>
            <ul className="nav justify-content-center">
              <li className="nav-item ">
                <Link
                  to="/aboutus"
                  className="nav-link p-3 text-muted font16white600 "
                  onClick={() => scrollTop()}
                >
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/blog"
                  className="nav-link p-3 text-muted font16white600"
                  onClick={() => scrollTop()}
                >
                  Blogs & News
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contactus"
                  className="nav-link p-3 text-muted font16white600"
                  onClick={() => scrollTop()}
                >
                  Contact us
                </Link>
              </li>

              <li className="nav-item ">
                <a
                  href="#faq"
                  onClick={() => FAQS()}
                  className="nav-link p-3 text-muted font16white600"
                >
                  {/* <Link
                    class="nav-link p-3 text-muted font16white600"
                    aria-current="page"
                    to="/"
                    onClick={() => FAQS()}
                  > */}
                  FAQs
                  {/* </Link> */}{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-2 d-flex justify-content-end   justiEndCenter">
            <MediaIconButton />
          </div>
        </div>{" "}
      </div>

      <div className="d-flex flex-wrap justify-content-center align-items-center border-top py-4 ">
        <ul className="nav justiStartCenter  justify-content-end list-unstyled d-flex pt-2 mb-5">
          <li className="ms-4">
            <div className=" text-decoration-none font14white400">
              {/* <Link to="/contactus" className=" text-decoration-none lh-1"> */}
              <span className="font14white400">
                © 2023 travelgenius. All right reserved.
              </span>
              {/* </Link> */}
            </div>
          </li>
        </ul>
      </div>
    </footer>
  );
}

// <li className="ms-4 ">
//   {/* <Link to="/contactus" className="text-decoration-none lh-1"> */}
//   <span className=" font14white400">Privacy Policy</span>
//   {/* </Link> */}
// </li>
// <li className="ms-4 ">
//   {/* <Link to="/contactus" className=" text-decoration-none lh-1"> */}
//   <span className="font14white400 ">Terms of Service</span>
//   {/* </Link> */}
// </li>
// <li className="ms-4 ">
//   {/* <Link to="/contactus" className=" text-decoration-none lh-1"> */}
//   <span className="font14white400 ">Cookies Settings</span>
//   {/* </Link> */}
// </li>
