import React from "react";
import Res from "../../../Images/Image/Restaurant.svg";
import Hotel from "../../../Images/Image/Hotel.svg";
import Genius from "../../../Images/Image/Genius.svg";
import Right from "../../../Images/Image/check-circle.svg";
import BlackButton from "../Button/BlackButton";
import BlueButtonWidth100 from "../Button/BlueButtonWidth100";

export default function Cards() {
  return (
    <div className="p-3 paddingLR3P" style={{ backgroundColor: "#F9FAFB" }}>
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-5 pb-5">
        <div className="col">
          <div className="card borderNone">
            <div className="padding30 ">
              <div className="d-flex justify-content-start align-items-center pb-4">
                <img src={Res} alt="" />
                <h5 className="cardTitleName">SpotQuest for Restaurants</h5>
              </div>
              <p className="card-text normalText14400 noramlTextheight textOverFlow22">
                SpotQuest for Restaurants is our Automated Intelligence platform
                designed for the restaurant industry. With SpotQuest,
                restaurants gain access to advanced tools for conducting
                location assessments, pricing analysis, and competition
                benchmarking. The platform empowers restaurants to proactively
                position themselves in the market and implement effective
                pricing strategies to stay one step ahead of the competition.
              </p>
              <hr />

              <div>
                <p className="cardgrayColorText pb-3">Popular Features</p>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className=" card-title rightTextMenu">
                    Menu Pricing Comparison
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Market and Competition Benchmarking
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Concepts/Cusines to Launch
                  </p>
                </div>
              </div>
              <a
                target="_blank"
                href="https://spotquest.ai/restaurant"
                className=""
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <BlueButtonWidth100 buttonText="Learn more" />
              </a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card borderNone">
            <div className="padding30">
              <div className="d-flex justify-content-start align-items-center pb-4">
                <img src={Hotel} alt="" />
                <h5 className="cardTitleName">SpotQuest for Hotels</h5>
              </div>
              <p className="card-text normalText14400 noramlTextheight textOverFlow22">
                SpotQuest for Hotels provides a range of specialized services to
                our esteemed hotel clients, including efficient Feasibility
                Studies, insightful SWOT analysis, and comprehensive Competition
                Assessment. By leveraging SpotQuest, hotels can significantly
                reduce the time required to deliver these critical studies,
                accelerating the decision-making process for crucial business
                strategies.
              </p>
              <hr />

              <div>
                <p className="cardgrayColorText pb-3">Popular Features</p>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Location Feasibility Studies
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">SWOT analysis</p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Competitor Analysis
                  </p>
                </div>
              </div>

              <a
                target="_blank"
                href="https://spotquest.ai/hotels"
                className=""
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <BlueButtonWidth100 buttonText="Learn more" />
              </a>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card borderNone">
            <div className="padding30">
              <div className="d-flex justify-content-start align-items-center pb-4">
                <img src={Genius} alt="" />
                <h5 className="cardTitleName">Geni-us.ai</h5>
              </div>
              <p className="card-text normalText14400 noramlTextheight textOverFlow22">
                Genius.ai is a travel discovery and planning platform that
                enables users to ensure that they exactly have a travel
                experience that they desire. Genius.ai helps travelers to
                discover small neighborhoods within a destination that fulfill
                their travel needs and helps them identify best stay options in
                those neighborhoods.
              </p>
              <hr />

              <div>
                <p className="cardgrayColorText pb-3">Popular Features</p>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Text Search based on your needs
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu">
                    Identify suitable city neighborhoods
                  </p>
                </div>
                <div className="d-flex justify-content-start align-items-center pb-3">
                  <img src={Right} alt="Right" />
                  <p className="card-title rightTextMenu"> Book best stays</p>
                </div>
              </div>
              <a
                target="_blank"
                href="https://geni-us.ai/"
                className=""
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <BlueButtonWidth100 buttonText="Learn more" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
