import React from "react";
import LogoImage112 from "../../../Images/Logo/White.svg";

export default function FooterLogo() {
  return (
    <div className="d-flex  align-items-center">
      <img src={LogoImage112} alt="logo" className="logoImageSize" />
    </div>
  );
}
