import React from "react";
import "./Styles/style.css";
import NavBar from "../NavBar/NavBar";
import MainSection from "./Components/MainSection";
import MiddleSection from "./Components/MiddleSection";
import LastSection from "./Components/LastSection";
import Footer from "../Footer/Footer";

export default function index() {
  return (
    <div>
      <NavBar />
      <MainSection />
      <MiddleSection />
      <LastSection />
      <Footer />
    </div>
  );
}
