import React, { useEffect, useState } from "react";
// import Logo from "../../Image/Componets/Logo/logo.svg";
import { Link } from "react-router-dom";
import BlackButton from "../Components/Button/BlackButton";
import Logo from "../Components/LOGO/Logo";
import WhiteButton from "../Components/Button/WhiteButton";

export default function NavBar(props) {
  return (
    <div className="">
      <nav
        className="navbar navbar-expand-lg  paddingLR3P  borderBottomNav"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="container-fluid px-0">
          <a className="navbar-brand" href="/">
            <Logo />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end align-items-center"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav my-auto mb-2 mb-lg-0 align-items-center">
              <li className="nav-item dropdown navHover px-2">
                <Link
                  className="nav-link  fnt16600 paddingLR10 paddingbutton navButtonText"
                  to="/"
                  id="navbarDropdownMenuLink "
                  role="button"
                  aria-expanded="false"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item navHover px-2">
                <Link
                  className="nav-link navButtonText  paddingbutton"
                  to="/blog"
                >
                  Blogs
                </Link>
              </li>
              <li className="nav-item navHover px-2">
                <Link
                  className="nav-link navButtonText  paddingbutton"
                  to="/accessibletravel"
                >
                  Accessible Travel
                </Link>
              </li>
              <li className="nav-item navHover px-2">
                <Link
                  className="nav-link navButtonText  paddingbutton"
                  to="/aboutus"
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item navHover px-2">
                <Link
                  className="nav-link navButtonText  paddingbutton"
                  to="/contactus"
                >
                  Contact Us
                </Link>
              </li>
              <li className="nav-item hideOnSmall px-2">
                <div className="blankLine"></div>
              </li>
              <li className="nav-item">
                <Link
                  className="text-decoration-none navButtonText"
                  to="/contactus"
                >
                  <WhiteButton buttonText="Get a demo" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
