import React from "react";
import LogoImage from "../../../Images/Logo/logo.svg";
import LogoImage112 from "../../../Images/Logo/Origin.svg";

export default function Logo(props) {
  return (
    <div className="d-flex  align-items-center">
      <img src={LogoImage112} alt="logo" className="logoImageSize" />
    </div>
  );
}
