import React from "react";

export default function BlueButtonWidth100(props) {
  return (
    <>
      <button
        type="button"
        class="btn orangeButton paddingbutton orangeButtonText d-flex align-items-center justify-content-center w-100"
      >
        {props.buttonText}
      </button>
    </>
  );
}
