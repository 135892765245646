import React from "react";
import userH from "../../../Images/Image/userHann.svg";
import Hannes from "./../../../Images/member/Hannes.png";
import DavidJarvis from "./../../../Images/member/DavidJarvis.png";
import DavidKing from "./../../../Images/member/DavidKing.png";
import Jagdish from "./../../../Images/member/Jagdish.png";
import Jurgen from "./../../../Images/member/Jurgen.png";
import Natalie from "./../../../Images/member/Natalie.png";
import Roshni from "./../../../Images/member/Roshni.png";
import Sachin from "./../../../Images/member/Sachin.png";
import Link from "../../../Images/Image/LinkedInblu.svg";

export default function OurLeadership() {
  return (
    <>
      <div className="pb-5">
        <div className="py-5 my-4">
          <div className="text-center w-100 blogtext mb-3 font16gray600">
            Our Leadership
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font36black700">
              Meet the People Who Make it Happen{" "}
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-md-4 g-4 pb-5">
          <div className="col text-center">
            <div className="pb-4">
              <img src={Hannes} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">Hannes Bos</p>
              <p className="fontUserName">Founder and CEO</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/hannesbos"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
          <div className="col text-center">
            <div className="pb-4">
              <img src={Sachin} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">Sachin Chauhan</p>
              <p className="fontUserName">Co-Founder and CTO</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/sachin-chauhan-37b68231"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
          <div className="col text-center">
            <div className="pb-3">
              <img src={Roshni} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">Roshni Pandey</p>
              <p className="fontUserName">Investor and Board Director</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/roshnipandey"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
          <div className="col text-center">
            <div className="pb-3">
              <img src={Jagdish} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">Jagdish Sidhu</p>
              <p className="fontUserName">Investor and Board Director</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/jagdish-sidhu"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-4 g-4 pb-5">
          <div className="col text-center">
            <div className="pb-4">
              <img src={DavidKing} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">David King</p>
              <p className="fontUserName">
                Board Director and Strategic Advisor
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/dklondon"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
          <div className="col text-center">
            <div className="pb-4">
              <img src={Jurgen} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">Jurgen Baumhoff</p>
              <p className="fontUserName">
                Board Director and Strategic Advisor
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/jurgenbaumhoff"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
          <div className="col text-center">
            <div className="pb-4">
              <img src={DavidJarvis} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">David Jarvis</p>
              <p className="fontUserName">Strategic Advisor</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/david-jarvis-6574b85"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
          <div className="col text-center">
            <div className="pb-4">
              <img src={Natalie} alt="Leadership" className="member" />
            </div>
            <div className="pb-3">
              <p className="font20black600">Natalie Nasser</p>
              <p className="fontUserName">Strategic Advisor</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <a
                target="_blank"
                href="https://www.linkedin.com/in/natalienasser?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3A[…]Ad_flagship3_search_srp_all%3Bh5nuWn64QUuvuvTt%2FsaTbw%3D%3D"
                className=""
                rel="noreferrer"
              >
                <div className="linkHover p-1 px-3">
                  <img src={Link} alt="linkedin" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
