import React from "react";

import ICON1 from "./../../../Images/AccessibilityTravel/icon1.svg";
import ICON2 from "./../../../Images/AccessibilityTravel/icon2.svg";
import ICON3 from "./../../../Images/AccessibilityTravel/icon3.svg";
import WhiteButton from "../../Components/Button/WhiteButton";
import PDF from "./../../../Images/pdf/Sage One Pager - Transforming Travel Accessibility Globally.pdf";

export default function LastSection() {
  return (
    <div className="at-last-container">
      <div className="at-last-sec-bg">
        <div className="at-last-crd-box">
          <img src={ICON1} alt="" />
          <span className="atlcbs1"> 20%</span>
          <span className="atlcbs2">
            of the global population has some form of disability
          </span>
        </div>
        <div className="at-last-crd-box">
          <img src={ICON2} alt="" />
          <span className="atlcbs1"> 140 Million</span>
          <span className="atlcbs2">
            disabled travelers in Europe and USA combined
          </span>
        </div>
        <div className="at-last-crd-box">
          <img src={ICON3} alt="" />
          <span className="atlcbs1"> $200 Billion</span>
          <span className="atlcbs2">
            worth of annual spend by disabled travelers globally on travel
          </span>
        </div>
      </div>

      <div className="at-bt-pgd">
        <span className="at-bt-pgd1">Want to know more?</span>
        <span className="at-bt-pgd2">Download our One Pager here</span>
        <WhiteButton
          buttonText="Download"
          submitChange={() => window.open(PDF, "_blank")}
        />
      </div>
    </div>
  );
}
