import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Anchorme } from "react-anchorme";
import { connect } from "react-redux";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import Spinner from "../../util/Spinner";
import User from "../../Images/Image/user.png";
import { getMoreBlogDetail, GetBlog } from "../../Redux/Actions/TravelAction";
import BlogPage from "./Components/BlogPage";
import BlackButton from "../Components/Button/BlackButton";
import Linkimg from "../../Images/Image/LinkedInBlack.svg";
import WhiteButton from "../Components/Button/WhiteButton";
import moment from "moment";
import Logo from "../Components/LOGO/Logo";

function BlogMore(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({});
  const [loding, setLoding] = useState(false);
  const [blogId, setBlogId] = useState("");
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let data = {
      type: "type_tg"
    };
    if (!props?.blogs) props.GetBlog(data);
  }, [props?.blogs]);
  const submitChange = (e) => {
    navigate("/blog");
  };
  const routeChange = (e) => {
    navigate(`/blogmore/${e}`);
    callAPI(e);
  };
  useEffect(() => {
    callAPI();
  }, []);
  useEffect(() => {
    if (blogId != "" && props.blogs) {
      setBlogData(props.blogs.filter((item) => item.Id !== blogId));
    }
  }, [blogId, props.blogs]);
  const callAPI = async (e) => {
    setLoding(true);
    let data = { id: e || location?.pathname.split("/")[2] };
    setBlogId(data.id);
    const response = await getMoreBlogDetail(data);
    if (response?.length !== 0) {
      setLoding(false);
      window.scrollTo(0, 0);
      setState(response[0]);
    } else {
      navigate(`/pagenotfound`);
    }
  };

  return (
    <>
      <NavBar />
      <div className="paddingLR3P">
        {loding ? (
          <Spinner />
        ) : (
          <>
            <div
              id="my-id2"
              className="py-4 "
              style={{ backgroundColor: "#fff" }}
            >
              <div className="d-flex justify-content-center align-items-center mb-3 blogimageBoxDetail ">
                <img
                  src={state?.titleimage}
                  className="imgsss blogImage imageBorder"
                  alt="..."
                />
              </div>

              <div className="pading20 padding0media">
                <div className="d-flex justify-content-center align-items-center w-100 mt-4 mb-2">
                  <div className="text-center d-flex justify-content-center align-items-center ">
                    <div className="w-100 blogTextHead">
                      {state?.title || ""}
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="my-4 font16black400">
                    <Anchorme target="_blank" rel="noreferrer noopener" style={{textDecoration:"none"}}>
                      {state?.summery || ""}
                    </Anchorme>
                  </div>

                  {!state?.content
                    ? null
                    : state.content.split("\r").map((_, i) => (
                        <>
                          <div key={i} className="my-4">
                            <p className="blogsTextData">
                              {" "}
                              <Anchorme style={{textDecoration:"none"}}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                {_}
                              </Anchorme>
                            </p>
                          </div>
                          {state.contentimages.length > 0 &&
                          (i === 3 || i === 4) ? (
                            <div className="d-flex justify-content-center align-items-center w-100 mt-4 ">
                              <img
                                src={
                                  !state.contentimages
                                    ? null
                                    : state.contentimages[i - 3]
                                }
                                className="img-fluid imageBorder blogimg "
                                alt="thumbnail"
                              />
                            </div>
                          ) : null}
                        </>
                      ))}

                  <div className="py-5 linebottom">
                    <p className="font18black400 font600"> Share this post</p>
                    <div className="d-flex justify-content-start align-items-center mt-2">
                      <div className="">
                        <a
                          target="_blank"
                          href="https://sg.linkedin.com/company/travelgenius-pte-ltd"
                          className=""
                          rel="noreferrer"
                        >
                          <img
                            src={Linkimg}
                            className="img-fluid imageBorder blogimg"
                            alt="..."
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="my-5">
                    <div className="d-flex ">
                      <div className="navProfilePicBox d-flex justify-content-center align-items-center">
                        <div className="navProfilePicBox d-flex justify-content-center align-items-center">
                          <img
                            src={User}
                            className="navProfilePic"
                            alt="user avatar"
                          />
                        </div>
                      </div>
                      <div className="mx-2">
                        <div className="font16black600">Travel Genius</div>
                        <div className="font14black400">
                          {moment(state?.blogdate).format("LL")}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="pt-5 my-5 ">
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font36black700">Related Posts</div>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4 mt-5 mb-4 pb-5 ">
          {!props?.blogs ? (
            <Spinner />
          ) : (
            <>
              <BlogPage
                blogs={blogData.slice(0, 3)}
                routeChange={routeChange}
              />
            </>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center w-100 mb-5 pb-5 ">
          <WhiteButton buttonText="View all" submitChange={submitChange} />
        </div>
      </div>
      <Footer />
    </>
  );
}

const travelStateToProps = (state) => {
  const { travelState } = state;
  return {
    blogs: travelState.blogs
  };
};

export default connect(travelStateToProps, { GetBlog })(BlogMore);
