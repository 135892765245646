import React from "react";
import LandingImage from "../../../Images/video/landingIMG.png";
import WhiteButton from "../Button/WhiteButton";
import lexiconLogo from "../../../Images/Companies/lexiconLogo.svg";
import PartnerLogo from "../../../Images/Companies/blueBox.png";
import metapoint from "../../../Images/Companies/metapoint.png";

import { useNavigate } from "react-router-dom";

const VideoSource =
  "https://storage.googleapis.com/travelgeniusimages/travel_genius.mp4";

export default function LandingVideo() {
  const navigate = useNavigate();

  const submitChange = (e) => {
    navigate("/contactus");
  };

  return (
    <>
      <div className="containerVideo ">
        <div
          dangerouslySetInnerHTML={{
            __html: `<video muted='true' style='width:100%' autoplay='true' loop playsinline src=${VideoSource} poster=${LandingImage}>
        </video>`
          }}
        ></div>

        <div className="top-left h-100 paddingLR3P">
          <div className="d-flex justify-content-start align-items-center w-50 ">
            <div className="">
              <div className="d-flex justify-content-start align-items-center paddingBottom4">
                <h1 className=" text-start font60white700">
                  Radicalizing Location Intelligence in Travel and Hospitality
                </h1>
              </div>
              <div className="d-flex justify-content-start align-items-center paddingBottom5">
                <div className=" font18white400 text-start">
                  Curating relevant gold-standard geospatial data for actionable
                  and accurate location insights.
                </div>
              </div>
              <div>
                <WhiteButton
                  buttonText="Contact us"
                  submitChange={submitChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5" style={{ backgroundColor: "#fff" }}>
        <div className="d-flex justify-content-center align-items-center py-4 pt-5">
          <p className=" font16black600">Our Partners</p>
        </div>

        <div className="flexmedia justify-content-center align-items-center mt-3 pb-4">
          <div className="logoBgcolor marBotMedia p-2 px-3 mx-5">
            <img src={lexiconLogo} className="imgs" alt="..." />
          </div>
          <div className="mx-5 logoBgcolor marBotMedia p-2 px-3">
            <img src={PartnerLogo} className="imgs3" alt="..." />
          </div>
          <div className="mx-5 logoBgcolor2 marBotMedia p-2 px-3">
            <img src={metapoint} className="imgs3" alt="..." />
          </div>
        </div>
      </div>
    </>
  );
}
