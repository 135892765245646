import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import FrequentlyAskedQus from "../Components/FAQ/FrequentlyAskedQus";
import OurProducts from "../Components/OurProducts/OurProducts";
import Cards from "../Components/Cards/Cards";
import LandingVideo from "../Components/LandingVideo/LandingVideo";
import AllPlateformOrangeBG from "../Components/AllPlateformOrangeBG/AllPlateformOrangeBG";
import { GetBlog } from "../../Redux/Actions/TravelAction";
import { connect } from "react-redux";
import BlogPage from "../Blog/Components/BlogPage";
import { useNavigate } from "react-router-dom";
import BlackButton from "../Components/Button/BlackButton";
import WhiteButton from "../Components/Button/WhiteButton";
import Spinner from "../../util/Spinner";
import IHG from "./../../Images/Image/IHG-Logo.jpg";

function Landing(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let data = {
      type: "type_tg",
    };
    if (!props.blogs) props.GetBlog(data);
  }, [props.blogs]);

  const routeChange = (e) => {
    const path = `/blogmore/${e}`;
    navigate(path);
  };
  const submitChange = (e) => {
    navigate(`/blog`);
  };
  return (
    <div style={{ backgroundColor: "#F9FAFB" }}>
      <NavBar />

      <LandingVideo />

      <OurProducts />
      <Cards />
      <Testinomial />
      <FrequentlyAskedQus />
      <AllPlateformOrangeBG />

      <div className="py-5  paddingLR3P" style={{ backgroundColor: "#fff" }}>
        <div className="py-5">
          <div className="text-center w-100 blogtext mb-3 font16gray600">
            Blogs & News
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font36black700">
              Stay Updated: Engage with Our Informative Blog Content
            </div>
          </div>
        </div>
      </div>
      <div className="paddingLR3P" style={{ backgroundColor: "#fff" }}>
        <div className="row row-cols-1 row-cols-md-3 g-4   pb-5  ">
          {!props?.blogs ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <BlogPage
                blogs={props.blogs.slice(0, 3)}
                routeChange={routeChange}
              />
            </React.Fragment>
          )}
        </div>
      </div>
      <div
        className="d-flex justify-content-center align-items-center w-100 pb-5 paddingLR3P"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="pb-4">
          <WhiteButton buttonText="View all" submitChange={submitChange} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

const travelStateToProps = (state) => {
  const { travelState } = state;
  return {
    blogs: travelState.blogs,
  };
};

export default connect(travelStateToProps, {
  GetBlog,
})(Landing);

const Testinomial = () => {
  return (
    <div className="testinomial-container">
      <div className="tetinimial-head">
        <h1>What Our Clients Say</h1>
        <span>
          Discover what our satisfied clients have to say
          <br /> about their experience with our products and services
        </span>
      </div>
      <div className="testinomial-inner">
        <div className="testinomila-title">
          <img src={IHG} alt="" />
          <span className="test-name-title">
            <span style={{ fontWeight: 800 }}>Mr Michael Beechey</span>
            <br />
            <span style={{ fontWeight: 500, fontSize: 12 }}>
              Cluster General manager IHG Hotels & Resorts
            </span>
          </span>
        </div>
        <div className="testinomila-content">
          <blockquote>
            <span>
              SpotQuest's insights have been indispensable in shaping our
              strategic decisions. Their AI-driven platform provided us with
              precise market analysis and location recommendations that helped
              shape our GTM
            </span>
          </blockquote>
        </div>
      </div>
    </div>
  );
};
