import React, { useState, useEffect } from "react";
import NavBar from "../NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import { GetBlog } from "../../Redux/Actions/TravelAction";
import { connect } from "react-redux";
import ImageBlogPage from "../../Images/Image/ImageBlogPage.svg";
import User from "../../Images/Image/user.png";
import moment from "moment";
import Spinner from "../../util/Spinner";
import Footer from "../Footer/Footer";
import BlogPage from "./Components/BlogPage";
import BlackButton from "../Components/Button/BlackButton";
import cardLinkMore from "../../Images/Image/cardLinkMore.svg";
import WhiteButton from "../Components/Button/WhiteButton";

function Blog(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let data = {
      type: "type_tg"
    };
    if (!props.blogs) props.GetBlog(data);
  }, [props.blogs]);

  const routeChange = (e) => {
    const path = `/blogmore/${e}`;
    navigate(path);
  };
  const submitChange = (e) => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="">
      <NavBar />
      <div className="paddingLR3P" style={{ backgroundColor: "#fff" }}>
        <div className="">
          {!props?.blogs ? (
            <Spinner />
          ) : (
            <>
              <div>
                {/* <div className="d-flex justify-content-start align-items-center w-100 mt-5 pt-5 ">
                  <div className="">
                    <div className=" w-100 font16black600 ">Blog</div>
                    <div className="text-center d-flex justify-content-start align-items-center pb-4">
                      <div className="">
                        <h2 className="font60black700 text-start">
                          {props?.blogs?.[0]?.title}
                        </h2>
                      </div>
                    </div>
              
                  </div>
                </div> */}
                <div>
                  <div className="row row-cols-1 row-cols-md-2 g-0 py-5 ">
                    <div
                      className="col d-flex justify-content-start align-items-center blogpageWidth card "
                      onClick={(e) => routeChange(props?.blogs?.[0]?.Id)}
                    >
                      <img
                        src={props?.blogs?.[0]?.titleimage}
                        className="imgs"
                        alt="..."
                      />
                    </div>
                    <div
                      className="col d-flex justify-content-start align-items-center py-4 "
                      style={{ paddingLeft: "5%" }}
                    >
                      <div className="w-100">
                        <div className="py-2">
                          <h2 className="font36black700 textOverFlow2Line ">
                            {props?.blogs?.[0]?.title}
                          </h2>
                        </div>
                        <div className="font16black400 text-start textOverFlow">
                          {props?.blogs?.[0]?.summery}
                        </div>
                        <div className="my-5">
                          <div className="d-flex ">
                            <div className="navProfilePicBox d-flex justify-content-center align-items-center">
                              <div className="navProfilePicBox d-flex justify-content-center align-items-center">
                                <img
                                  src={User}
                                  className="navProfilePic"
                                  alt="user avatar"
                                />
                              </div>
                            </div>
                            <div className="mx-2">
                              <div className="font14black600">
                                Travel Genius
                              </div>
                              <div className="font14black400">
                                {moment(props?.blogs?.[0]?.blogdate).format(
                                  "LL"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-100 ">
                          <div className="card-body w-100 d-flex justify-content-end align-items-center">
                            <div
                              className="card-link cardLink readMehover p-2 px-4"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                routeChange(props?.blogs?.[0]?.Id)
                              }
                            >
                              Read more
                              <img src={cardLinkMore} className="" alt="..." />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </>
          )}
          {/* <div className="py-5 d-flex justify-content-center align-items-center">
            <div className="px-2">
              <WhiteButton buttonText="View all" />
            </div>
            <div className="px-2 navHover fnt16600 paddingLR10 paddingbutton">
              <p className="navButtonText">News</p>
            </div>
            <div className="px-2 navHover fnt16600 paddingLR10 paddingbutton">
              <p className="navButtonText">Blogs</p>
            </div>
          </div> */}
          <div className="row row-cols-1 row-cols-md-3 g-4 mt-5 mb-4 pb-5">
            {!props?.blogs ? (
              <Spinner />
            ) : (
              <>
                <BlogPage
                  blogs={props.blogs.slice(1, props.blogs.length)}
                  routeChange={routeChange}
                />
              </>
            )}
          </div>

          <div className="d-flex justify-content-center align-items-center w-100 mb-5 pb-5 ">
            <WhiteButton buttonText="View all" submitChange={submitChange} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const travelStateToProps = (state) => {
  const { travelState } = state;
  return {
    blogs: travelState.blogs
  };
};

export default connect(travelStateToProps, {
  GetBlog
})(Blog);
