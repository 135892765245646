import React from "react";
import image1 from "../../../Images/Image/Image1.jpg";

export default function CenterTitle() {
  return (
    <>
      {" "}
      <div className="landingPageBg2 py-5 paddingLR3P ">
        <div className="py-5">
          <div className="w-100  d-flex justify-content-center align-items-center pb-4">
            <div className="px-5  ">
              <div className=" padding0media margin0media">
                <p className="font56black700 text-center">
                  Democratizing Location Intelligence for All in the Travel and
                  Hospitality Sector
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center w-100">
            <div className="w-50">
              <p className="font18black400 text-center  d-flex justify-content-center align-items-center">
                We use data and technology to provide the most accurate and
                deepest insights to businesses as well as travelers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-4 h-vh-92 parent">
        <img src={image1} className="imgsAbout" />
      </div>
      <div className="pb-5 paddingLR3P">
        <div className="flexmedia justify-content-between align-items-start w-100 py-5">
          <div className="w-50meida ">
            <h1 className="font48black700 font48media">Our Story</h1>
          </div>
          <div className="w-50meida">
            <p className="font18gray400">
              We are a company founded by engineers, Data Professionals and
              hospitality  industry experts who have recognized the limitations
              and gaps in the current methods used for conducting location
              assessment studies and feasibility analysis in the travel and
              hospitality industry. What sets us apart is our deep understanding
              of the travel and hospitality industry coupled with our technical
              prowess. Our team of experienced engineers and industry
              specialists combines their expertise to provide comprehensive AI
              powered and data-driven solutions that empower businesses to make
              informed decisions.
            </p>
          </div>
        </div>

        <div className="flexmedia justify-content-between align-items-start w-100 pb-5 pt-3">
          <div className="w-50meida ">
            <h1 className="font48black700 font48media">Our Mission</h1>
          </div>
          <div className="w-50meida">
            <p className="font18gray400">
              At TravelGenius, our mission is to radically transform the
              understanding and consumption of location intelligence and
              geospatial data. With our automated global map platform we aspire
              to empower businesses across sectors to make actionable and
              optimised decisions. We are forging the future of location
              intelligence with our innovations and want you to Join us on this
              journey as we revolutionise the way decision-makers leverage
              location insights for business success.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
