import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/Store/Store";
import "./Page/CSS/Style.css";
import Blog from "./Page/Blog/Blog";
import BlogMore from "./Page/Blog/BlogMore";
import Landing from "./Page/LandingPage/Landing";
import AboutUS from "./Page/AboutUs/AboutUS";
import ContactUs from "./Page/ContactUs/ContactUs";
import PageNotFound from "./Page/404page/PageNotFound";
import AccessibleTravel from "./Page/AccessibleTravel";

const router = createBrowserRouter([
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: `/blogmore/:id`,
    element: <BlogMore />,
  },
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/aboutus",
    element: <AboutUS />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/accessibletravel",
    element: <AccessibleTravel />,
  },
  {
    path: "/pagenotfound",
    element: <PageNotFound />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
