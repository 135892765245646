import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CenterTitle from "../Components/CenterTitle/CenterTitle";
import OurProducts from "../Components/OurProducts/OurProducts";
import { Card } from "react-bootstrap-v5";
import Cards from "../Components/Cards/Cards";
import { connect } from "react-redux";
import { GetBlog } from "../../Redux/Actions/TravelAction";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import BlogPage from "../Blog/Components/BlogPage";
import OurLeadership from "../Components/OurLeadership/OurLeadership";
import WhiteButton from "../Components/Button/WhiteButton";
import Spinner from "../../util/Spinner";

function AboutUS(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let data = {
      type: "type_tg"
    };
    if (!props.blogs) props.GetBlog(data);
  }, [props.blogs]);

  const routeChange = (e) => {
    const path = `/blogmore/${e}`;
    navigate(path);
  };
  const submitChange = (e) => {
    navigate("/blog");
  };
  return (
    <>
      <NavBar />
      <div className="">
        <CenterTitle />
      </div>
      <OurProducts />
      <Cards />
      <div className="paddingLR3P">
        <OurLeadership />
      </div>
      <div className="py-5 " style={{ backgroundColor: "#F9FAFB" }}>
        <div className="pt-5">
          <div className="text-center w-100 blogtext mb-3 font16gray600">
            Press
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font36black700">News from TravelGenius</div>
          </div>
        </div>
      </div>
      <div className="paddingLR3P" style={{ backgroundColor: "#F9FAFB" }}>
        <div className="row row-cols-1 row-cols-md-3 g-4   pb-5 ">
          {!props?.blogs ? (
            <Spinner />
          ) : (
            <>
              <BlogPage
                blogs={props.blogs.slice(0, 3)}
                routeChange={routeChange}
              />
            </>
          )}
        </div>{" "}
      </div>
      <div
        className="d-flex justify-content-center align-items-center w-100  pb-5 "
        style={{ backgroundColor: "#F9FAFB" }}
      >
        <div className="py-4">
          <WhiteButton buttonText="View all" submitChange={submitChange} />
        </div>
      </div>
      <Footer />
    </>
  );
}
const travelStateToProps = (state) => {
  const { travelState } = state;
  return {
    blogs: travelState.blogs
  };
};

export default connect(travelStateToProps, { GetBlog })(AboutUS);
