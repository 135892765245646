import React from "react";
import BlackButton from "../Button/BlackButton";
import { useNavigate } from "react-router-dom";

export default function OurProducts() {
  const navigate = useNavigate();

  const submitChange = (e) => {
    navigate("/contactus");
  };
  return (
    <>
      <div className="d-flex justify-content-center align-items-center w-100 py-5 paddingLR3P" style={{ backgroundColor: "#F9FAFB" }}>
        <div className="py-4 my-2">
          <div className="text-center w-100 blogtext mb-3 font16gray600">
            Our Products
          </div>
          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font36black700">
              Transforming Travel and Hospitality through AI and Geospatial Data
            </div>
          </div>

          <div className="text-center d-flex justify-content-center align-items-center pb-4">
            <div className="w-75 font16gray400">
              TravelGenius offers a comprehensive range of data and intelligence
              solutions tailored for diverse businesses within the travel and
              hospitality industry. Our suite of products caters to Hotel
              Chains, Restaurant Owners, Consultants, and ambitious individuals
              seeking to leverage location and market data to revolutionize
              their business strategies and decision-making processes.
            </div>
          </div>
          <div className="text-center d-flex justify-content-center align-items-center">
            <div className="mx-2">
              <BlackButton
                buttonText="Contact Sales"
                submitChange={submitChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
