import React from "react";
import { useNavigate } from "react-router-dom";
import BlackButton from "../Button/BlackButton";

export default function FrequentlyAskedQus() {
  const navigate = useNavigate();

  const submitChange = (e) => {
    navigate(`/contactus`);
  };

  return (
    <div className="contactFirstBg d-flex justify-content-center align-items-center paddingLR3P">
      <div className="w-75media  pading20 p-5" id="faq">
        <div className="py-4 px-5media">
          <div className="w-100  d-flex justify-content-center align-items-center">
            <div className="w-75">
              <p className="font36black700 text-center  pb-4">
                Frequently asked questions
              </p>
              {/* <p className="text-center font16gray400">
                Frequently asked questions ordered by popularity. Remember that
                if the visitor has not committed to the call to action, they may
                still have questions (doubts) that can be answered.
              </p> */}
            </div>
          </div>

          <div className="py-5 my-4">
            <div
              className="accordion accordion-flush  "
              id="accordionFlushExample"
            >
              <div
                className="accordion-item "
                style={{ borderTop: "1px solid #000" }}
              >
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed faqbgInputColor font18black600"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What do we Do?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse faqbgInputColor"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body ">
                    We are a company focused on providing excellence in
                    geospatial intelligence through our tech and data enabled
                    custom map platforms that specifically curate data for the
                    travel and hospitality industry.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed faqbgInputColor font18black600"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Who are our customers?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse faqbgInputColor"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    We have a wide spectrum of customers ranging from Hotel
                    Chains, Restaurants, Consultants as well as individual
                    travelers
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingFour">
                  <button
                    className="accordion-button collapsed faqbgInputColor font18black600"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    Why should you use our products?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse faqbgInputColor"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    If you are looking for getting location insights for any
                    location spot, weather it be Feasibility analysis, Location
                    Quality Assessment, Competition and Price Benchmarking or
                    just a traveler trying to find where to travel next, our
                    products will assist you in all of the above.
                  </div>
                </div>
              </div>
              {/* <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree2">
                  <button
                    className="accordion-button collapsed faqbgInputColor font18black600"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree2"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree2"
                  >
                    How long does it take to get the analysis delivered?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree2"
                  className="accordion-collapse collapse faqbgInputColor"
                  aria-labelledby="flush-headingThree2"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    SpotQuest is an automated platform which generates analysis
                    in real time, which then is quality checked by our in-house
                    analysts and delivered in the form of an analytics report to
                    your email in 5-7 days.
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center pb-3">
            <h1 className="font30black700 textaline">Still have questions?</h1>
          </div>
          {/* <div className="d-flex justify-content-center align-items-center pb-4">
            <p className="text-center font16gray400">
              Support details to capture customers that might be on the fence.
            </p>
          </div> */}
          <div className=" d-flex justify-content-center align-items-center py-3">
            <BlackButton buttonText="Contact us" submitChange={submitChange} />
          </div>
        </div>
      </div>
    </div>
  );
}
