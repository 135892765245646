import React from "react";
import BlackButton from "../Button/BlackButton";
import { useNavigate } from "react-router-dom";
import Vector3 from "../../../Images/Image/Vector3.svg";

export default function AllPlateformOrangeBG() {
  const navigate = useNavigate();

  const submitChange = (e) => {
    navigate(`/contactus`);
  };
  return (
    <div className="allplatformBGcolor py-5">
      <div className="py-5 my-4">
        <div className="allplateformtextBox">
          <img src={Vector3} className="imageHeightAllplate" />
        </div>
        <div className="d-flex justify-content-center align-items-center pb-4 px-4">
          <h1 className="font36black600">
            All-in-one platform for business
          </h1>
        </div>
        <div className="d-flex justify-content-center align-items-center pb-4 px-4">
          <p className="text-center font18black400">
            Curating relevant gold-standard geospatial data for actionable and
            accurate location insights.
          </p>
        </div>
        <div className=" d-flex justify-content-center align-items-center py-3">
          <BlackButton buttonText="Contact Sales"  submitChange={submitChange} />
        </div>
      </div>
    </div>
  );
}
