import React from "react";

export default function MainSection() {
  return (
    <div className="at-main-section ">
      <div className="at-quotes-wrap">
        <div className="at-quotes">
          <span className="at-quotes-first">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="24"
              viewBox="0 0 32 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0564 15.9396C18.493 10.9095 21.2277 1.92007 29.5 0L31 3.06383C29.1667 3.91489 25.6 6.43404 26 9.70213L25.93 9.76467C29.3564 10.2292 32 13.2248 32 16.8511C32 20.7993 28.866 24 25 24C21.134 24 18 20.7993 18 16.8511C18 16.5423 18.0192 16.2381 18.0564 15.9396Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.0563527 15.9396C0.493019 10.9095 3.22769 1.92007 11.5 0L13 3.06383C11.1667 3.91489 7.6 6.43404 8 9.70213L7.93001 9.76467C11.3564 10.2292 14 13.2248 14 16.8511C14 20.7993 10.866 24 7 24C3.13401 24 0 20.7993 0 16.8511C0 16.5423 0.0191672 16.2381 0.0563527 15.9396Z"
                fill="white"
              />
            </svg>
          </span>
          <span className="at-quotes-second">Our mission</span>
          <span className="at-quotes-third">
            Our mission is to revolutionize travel for people with disabilities
            and to create an inclusive, seamless, and empowering travel
            experience. Through innovation and a commitment to accessibility, we
            strive to make the joy of travel a reality for everyone, everywhere,
            regardless of their physical abilities.
          </span>
        </div>
      </div>
    </div>
  );
}
