import React from "react";
import image from "../../../Images/Image/LinkedIn.svg";

export default function MediaIconButton() {
  return (
    <div className="justiEndCenter">
      <a
        target="_blank"
        href="https://www.linkedin.com/company/travelgenius-pte-ltd"
        className=""
        rel="noreferrer"
      >
        <img src={image} />
      </a>
    </div>
  );
}
